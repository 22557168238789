import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1 className="d-flex align-items-center justify-content-center">
        <span>Privacy Policy</span>
      </h1>
      <h2 className="fs-4 mt-4 p-2">1. Limitations:</h2>
      <p>
        Essae is not liable to you or any third party for indirect, direct,
        special or other consequential or incidental damages (Not limited to any
        lost profits or revenue, interruption, loss of programs or other
        information, or any other) arising directly or indirectly from (i) your
        access to the Service, or any Content, products or services distributed
        on or provided through the website and Service, (ii) for any failure of
        the Service; whether as a result of errors, loss of data, defects,
        viruses, interruptions or delays in operations or transmission or any
        other cause, based on warranty, contract or any other legal explains,
        even if we or our suppliers have expressly advised of the possibility of
        such damages, product or services distributed on or provided through the
        Site and Service, is caused by our gross negligence.
      </p>
      <p>
        Some courses of jurisdiction restrict the damages, so the above
        exclusions and limitations may not apply to you. If any part of the
        limitation of liability given above is unenforceable under applicable
        law, our aggregate liability will be limited to the max extent permitted
        by law in the applicable jurisdiction.
      </p>
      <p>
        You are responsible for paying all applicable Taxes, and will pay,
        indemnify and hold Essae harmless from any Taxes and any costs
        associated with the collection or withholding thereof, including
        penalties and interest. Unless otherwise stated in an Invoice, the Fees
        listed on an Invoice are not inclusive of any applicable Taxes.
      </p>

      <h2 className="fs-4 mt-4 p-2">
        2. Information collected while using the application:
      </h2>
      <p>
        While using our application, in order to provide features of our
        application, we may collect, with your prior permission:
      </p>
      <ul>
        <li>
          Phone number to verify registered users and authenticate the number by
          OTP to login into the app.
        </li>
        <li>
          Information regarding your location. We do not use this information to
          identify you personally without your consent.
        </li>
        <li>Information from your Device's phone book (contacts list).</li>
        <li>
          Pictures and other information from your device's camera and photo
          library.
        </li>
      </ul>
      <p>
        We use this information to provide features of our service, to improve
        and customize our service. The information may be uploaded to the
        Company's servers and/or a service provider's server or it may be simply
        stored on your device. The location is fetched to calculate the distance
        traveled by you on duty time to calculate your claims accurately by the
        company. You can enable or disable access to this information at any
        time, through your device settings.
      </p>

      <h2 className="fs-4 mt-4 p-2">3. Cancellation and Termination:</h2>
      <p>
        You may cancel your registration at any time on the Site. Upon your
        cancellation we will not provide you any refunds; however you can use
        the Service until the end of your billing period or the end of your free
        trial period. We reserve the right to suspend your access to the Service
        and/or terminate this Agreement at any time without cause or notice.
      </p>
      <p>
        Data from third party services: Essae has no control over and takes no
        responsibility for, the content, privacy policies, or practices of any
        3rd party services from where data is provided toward the Service. Links
        to other Sites and Services: your use only.
      </p>
      <p>
        Our Service may contain links to third-party sites that are not owned or
        controlled by Essae. We strongly recommend to read the terms and
        conditions and privacy policy of any third-party sites that you visit.
      </p>

      <h2 className="fs-4 mt-4 p-2">4. Governing Law:</h2>
      <p>If you use the Service:</p>
      <p>Changes to this Terms and Conditions.</p>
      <p>
        We might change or replace these Terms and Conditions at our sole
        discretion, by publishing the updated Terms and Conditions on the Site.
        If your use of our Service after any such changes reflects your
        acceptance of the new Terms and Conditions and if you do not agree with
        the modified or replaced Terms and Conditions, please do not use, access
        or continue to access the Service.
      </p>

      <h2 className="fs-4 mt-4 p-2">5. Payments:</h2>
      <p>
        When it comes to payment information, you show that the information is
        perfect, that you are completely authorized to use the payment method
        provided, and you will let us know about changes to the payment
        information. Essae uses the payment platform of 3rd party, the Stripe
        API, Stripe. Your Stripe account to process credit and debit card
        transactions for your Essae account. Essae is a “Partner Application” as
        defined in the terms and conditions of Stripe Travelize shall not be
        liable for any payments and monetary transactions that occur through
        your use of the Service and agree that all payments and monetary
        transactions are handled by Stripe that Essae shall not be liable for
        any issues regarding financial and monetary transactions between you and
        any other party, including Stripe.
      </p>

      <h2 className="fs-4 mt-4 p-2">6. Survival:</h2>
      <p>
        The provisions of these terms which by their nature should survive the
        termination of these terms shall survive such termination.
      </p>
      <p>
        Entire Agreement: This entire agreement contains all the terms agreed by
        the parties relating to its subject matter. It replaces all previous
        discussions, understandings, and agreements.
      </p>

      <h2 className="fs-4 mt-4 p-2">7. Contact Us:</h2>
      <p>
        Should you have any questions regarding these Terms and Conditions or
        our Site and Service, please contact us here.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
